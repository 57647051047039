<template>
  <div id="materialReportMask">
    <el-dialog
      :title="materialReportMaskFormTitle"
      width="800px"
      :visible.sync="materialReportMaskDialogVisible"
      :close-on-click-modal="false"
      @close="materialReportMaskDialogClose"
    >
      <el-form
        ref="materialReportMaskFormRef"
        :model="materialReportMaskForm"
        :rules="materialReportMaskFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品(物料)名称" prop="materialName">
              <el-input v-model="materialReportMaskForm.materialName" placeholder="请输入产品(物料)名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产批号" prop="batchNum">
              <el-input v-model="materialReportMaskForm.batchNum" placeholder="请输入生产批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批量" prop="batchSize">
              <el-input v-model="materialReportMaskForm.batchSize" placeholder="请输入批量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="materialReportMaskForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请验日期" prop="checkDate">
              <el-date-picker v-model="materialReportMaskForm.checkDate" placeholder="请选择请验日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker v-model="materialReportMaskForm.reportDate" placeholder="请选择报告日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验依据" prop="basis">
              <el-input v-model="materialReportMaskForm.basis" placeholder="请输入检验依据" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="外观：口罩外观应整洁、形状完好，表面不得有破损、污渍" prop="appearance" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.appearance">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="结构与尺寸：口罩佩戴好后，应能罩住佩戴者的口、鼻至下颌。应符合型号规格的规定，最大偏差应不超过±5%" prop="size" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.size">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="鼻夹：口罩上应配有鼻夹，鼻夹由可塑性材料制成；鼻夹长度应不小于8.0 cm" prop="noseclip" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.noseclip">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="口罩带：每根口罩带与口罩体连接点处的断裂强力应不小于10 N" prop="maskBelt" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.maskBelt">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="细菌过滤效率：细菌过滤效率应不小于95%" prop="bacterialFilter" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.bacterialFilter">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="通气阻力：口罩两侧面进行气体交换的通气阻力应不大于49Pa/cm2" prop="ventilationResistance" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.ventilationResistance">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="微生物指标：细菌菌落总数≤100CFU/g" prop="microbe1" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.microbe1">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="微生物指标：大肠菌群不得检出" prop="microbe2" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.microbe2">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="微生物指标：绿脓杆菌不得检出" prop="microbe3" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.microbe3">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="微生物指标：金黄色葡萄球菌不得检出" prop="microbe4" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.microbe4">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="微生物指标：溶血性链球菌不得检出" prop="microbe5" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.microbe5">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="微生物指标：真菌不得检出" prop="microbe6" label-width="600px">
              <el-radio-group v-model="materialReportMaskForm.microbe6">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验结论" prop="conclusion">
              <el-radio-group v-model="materialReportMaskForm.conclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="materialReportMaskForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告人" prop="reporter">
              <el-input v-model="materialReportMaskForm.reporter" placeholder="请输入报告人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="reporterDate">
              <el-date-picker v-model="materialReportMaskForm.reporterDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="materialReportMaskForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="reviewerDate">
              <el-date-picker v-model="materialReportMaskForm.reviewerDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="materialReportMaskForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="approverDate">
              <el-date-picker v-model="materialReportMaskForm.approverDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="materialReportMaskDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialReportMaskFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品(物料)名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入产品(物料)名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialReportMaskPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialName" label="产品(物料)名称" />
      <el-table-column prop="batchNum" label="生产批号" />
      <el-table-column prop="batchSize" label="批量" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column label="请验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.checkDate">{{ scope.row.checkDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="basis" label="检验依据" />
      <el-table-column label="外观">
        <template slot-scope="scope">
          <span v-if="scope.row.appearance === 0">不合格</span>
          <span v-if="scope.row.appearance === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="结构与尺寸">
        <template slot-scope="scope">
          <span v-if="scope.row.size === 0">不合格</span>
          <span v-if="scope.row.size === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="鼻夹">
        <template slot-scope="scope">
          <span v-if="scope.row.noseclip === 0">不合格</span>
          <span v-if="scope.row.noseclip === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="口罩带">
        <template slot-scope="scope">
          <span v-if="scope.row.maskBelt === 0">不合格</span>
          <span v-if="scope.row.maskBelt === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="细菌过滤效率">
        <template slot-scope="scope">
          <span v-if="scope.row.bacterialFilter === 0">不合格</span>
          <span v-if="scope.row.bacterialFilter === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="通气阻力">
        <template slot-scope="scope">
          <span v-if="scope.row.ventilationResistance === 0">不合格</span>
          <span v-if="scope.row.ventilationResistance === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="细菌菌落总数≤100CFU/g">
        <template slot-scope="scope">
          <span v-if="scope.row.microbe1 === 0">不合格</span>
          <span v-if="scope.row.microbe1 === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="大肠菌群不得检出">
        <template slot-scope="scope">
          <span v-if="scope.row.microbe2 === 0">不合格</span>
          <span v-if="scope.row.microbe2 === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="绿脓杆菌不得检出">
        <template slot-scope="scope">
          <span v-if="scope.row.microbe3 === 0">不合格</span>
          <span v-if="scope.row.microbe3 === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="金黄色葡萄球菌不得检出">
        <template slot-scope="scope">
          <span v-if="scope.row.microbe4 === 0">不合格</span>
          <span v-if="scope.row.microbe4 === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="溶血性链球菌不得检出">
        <template slot-scope="scope">
          <span v-if="scope.row.microbe5 === 0">不合格</span>
          <span v-if="scope.row.microbe5 === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="真菌不得检出">
        <template slot-scope="scope">
          <span v-if="scope.row.microbe6 === 0">不合格</span>
          <span v-if="scope.row.microbe6 === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="检验结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 0">不合格</span>
          <span v-if="scope.row.conclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="reporter" label="报告人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reporterDate">{{ scope.row.reporterDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approverDate">{{ scope.row.approverDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialReportMaskPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMaterialReport, deleteMaterialReport, updateMaterialReport, selectMaterialReportInfo, selectMaterialReportList } from '@/api/quality/materialReport'

export default {
  data () {
    return {
      materialReportMaskDialogVisible: false,
      materialReportMaskFormTitle: '',
      materialReportMaskForm: {
        id: '',
        materialName: '',
        batchNum: '',
        batchSize: '',
        spec: '',
        checkDate: '',
        reportDate: '',
        basis: '',
        appearance: '',
        size: '',
        noseclip: '',
        maskBelt: '',
        bacterialFilter: '',
        ventilationResistance: '',
        microbe1: '',
        microbe2: '',
        microbe3: '',
        microbe4: '',
        microbe5: '',
        microbe6: '',
        conclusion: '',
        remarks: '',
        reporter: '',
        reporterDate: '',
        reviewer: '',
        reviewerDate: '',
        approver: '',
        approverDate: '',
        type: 8
      },
      materialReportMaskFormRules: {
        materialName: [{ required: true, message: '产品(物料)名称不能为空', trigger: ['blur', 'change']}]
      },
      materialReportMaskPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: '',
        type: 8
      }
    }
  },
  created () {
    selectMaterialReportList(this.searchForm).then(res => {
      this.materialReportMaskPage = res
    })
  },
  methods: {
    materialReportMaskDialogClose () {
      this.$refs.materialReportMaskFormRef.resetFields()
    },
    materialReportMaskFormSubmit () {
      if (this.materialReportMaskFormTitle === '一次性使用医用口罩非灭菌检验报告书详情') {
        this.materialReportMaskDialogVisible = false
        return
      }
      this.$refs.materialReportMaskFormRef.validate(async valid => {
        if (valid) {
          if (this.materialReportMaskFormTitle === '新增一次性使用医用口罩非灭菌检验报告书') {
            await addMaterialReport(this.materialReportMaskForm)
          } else if (this.materialReportMaskFormTitle === '修改一次性使用医用口罩非灭菌检验报告书') {
            await updateMaterialReport(this.materialReportMaskForm)
          }
          this.materialReportMaskPage = await selectMaterialReportList(this.searchForm)
          this.materialReportMaskDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialReportMaskFormTitle = '新增一次性使用医用口罩非灭菌检验报告书'
      this.materialReportMaskDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialReport(row.id)
        if (this.materialReportMaskPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.materialReportMaskPage = await selectMaterialReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialReportMaskFormTitle = '修改一次性使用医用口罩非灭菌检验报告书'
      this.materialReportMaskDialogVisible = true
      this.selectMaterialReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialReportMaskFormTitle = '一次性使用医用口罩非灭菌检验报告书详情'
      this.materialReportMaskDialogVisible = true
      this.selectMaterialReportInfoById(row.id)
    },
    selectMaterialReportInfoById (id) {
      selectMaterialReportInfo(id).then(res => {
        this.materialReportMaskForm.id = res.id
        this.materialReportMaskForm.materialName = res.materialName
        this.materialReportMaskForm.batchNum = res.batchNum
        this.materialReportMaskForm.batchSize = res.batchSize
        this.materialReportMaskForm.spec = res.spec
        this.materialReportMaskForm.checkDate = res.checkDate
        this.materialReportMaskForm.reportDate = res.reportDate
        this.materialReportMaskForm.basis = res.basis
        this.materialReportMaskForm.appearance = res.appearance
        this.materialReportMaskForm.size = res.size
        this.materialReportMaskForm.noseclip = res.noseclip
        this.materialReportMaskForm.maskBelt = res.maskBelt
        this.materialReportMaskForm.bacterialFilter = res.bacterialFilter
        this.materialReportMaskForm.ventilationResistance = res.ventilationResistance
        this.materialReportMaskForm.microbe1 = res.microbe1
        this.materialReportMaskForm.microbe2 = res.microbe2
        this.materialReportMaskForm.microbe3 = res.microbe3
        this.materialReportMaskForm.microbe4 = res.microbe4
        this.materialReportMaskForm.microbe5 = res.microbe5
        this.materialReportMaskForm.microbe6 = res.microbe6
        this.materialReportMaskForm.conclusion = res.conclusion
        this.materialReportMaskForm.remarks = res.remarks
        this.materialReportMaskForm.reporter = res.reporter
        this.materialReportMaskForm.reporterDate = res.reporterDate
        this.materialReportMaskForm.reviewer = res.reviewer
        this.materialReportMaskForm.reviewerDate = res.reviewerDate
        this.materialReportMaskForm.approver = res.approver
        this.materialReportMaskForm.approverDate = res.approverDate
        this.materialReportMaskForm.type = res.type
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportMaskPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportMaskPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportMaskPage = res
      })
    }
  }
}
</script>

<style>
</style>
